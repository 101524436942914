$font-stack: 'Poppins', sans-serif
$font-stack-thin: 'Poppins', sans-serif
$font-stack-300: 300
$font-uppercase: uppercase
$background-dark-mode: #020202
$background-light-mode: rgb(255, 255, 255)
$dark-mode-text-color: #FFFFFF
$light-mode-text-color: #303030
$breakpoint-xxl: 1400px
$breakpoint-xl: 1200px
$breakpoint-lg: 992px
$breakpoint-md: 768px
$breakpoint-sm: 576px
$breakpoint-xs: 382px
$borders-horizontal-darkmode: 1px solid #FFC309
$borders-horizontal-lightmode: 1px solid #303030
$borders-vertical-lightmode: 1px solid #FFC309 
$input-border-darkmode:  1px solid #FFFFFF
$input-border-lightmode: 1px solid  #303030
$input-background-darkmode: rgba(255, 255, 255, 0.5)
$input-background-lightmode: rgba(255, 255, 255, 0.2)
$input-box-shadow-darkmode: rgba(255, 255, 255, 0.266)
$input-box-shadow-lightmode: rgba(0, 0, 0, 0.1)
$form-darkmode-button-color: #FFFFFF
$form-lightmode-button-color: #303030
$hr-background-color: #FFC309
$lightmode-button: #020202
$darkmode-button: #FFFFFF
$lightmode-background-color-link: #FFC309
$icon-darkmode: #FFFFFF
$icon-lightmode: #303030
$button-color-focus-darkmode: rgba(255, 195, 9, 1)
$button-color-focus-lightmode: #020202
$line-height-text: 3
$line-height-text-xs: 2.2
$button-border-lightmode: linear-gradient(to right, #303030 3px, transparent 3px) 0 0, linear-gradient(to right, #303030 3px, transparent 3px) 0 100%, linear-gradient(to left, #303030 3px, transparent 3px) 100% 0, linear-gradient(to left, #303030 3px, transparent 3px) 100% 100%, linear-gradient(to bottom, #303030 3px, transparent 3px) 0 0, linear-gradient(to bottom, #303030 3px, transparent 3px) 100% 0, linear-gradient(to top, #303030 3px, transparent 3px) 0 100%, linear-gradient(to top, #303030 3px, transparent 3px) 100% 100%
$button-border-darkmode: linear-gradient(to right, #FFFFFF 3px, transparent 3px) 0 0, linear-gradient(to right, #FFFFFF 3px, transparent 3px) 0 100%, linear-gradient(to left, #FFFFFF 3px, transparent 3px) 100% 0, linear-gradient(to left, #FFFFFF 3px, transparent 3px) 100% 100%, linear-gradient(to bottom, #FFFFFF 3px, transparent 3px) 0 0, linear-gradient(to bottom, #FFFFFF 3px, transparent 3px) 100% 0, linear-gradient(to top, #FFFFFF 3px, transparent 3px) 0 100%, linear-gradient(to top, #FFFFFF 3px, transparent 3px) 100% 100%

