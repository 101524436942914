@use 'variables'
    
@mixin flex-space-between
    display: flex
    justify-content: space-between
    align-items: center

@mixin flex-space-around
    display: flex
    justify-content: space-around
    padding: 1rem 0

@mixin flex-space-around-center
    display: flex
    justify-content: space-around
    align-items: center

@mixin flex-center
    display: flex
    justify-content: center
    align-items: center
    gap: 5rem

@mixin flex-center-no-gap
    display: flex
    justify-content: center
    align-items: center


@mixin flex-column
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

@mixin flex-start
    display: flex
    justify-content: center
    align-items: flex-start


@mixin flex-end
    display: flex
    justify-content: flex-end
    align-items: center

@mixin flex-column-align-center
    display: flex
    flex-direction: column
    align-items: center

@mixin flex-wrap
    display: flex
    flex-wrap: wrap

@mixin borders-horizontal
    border: 2px solid #174C4F

@mixin lists-style-darkmode
    color: variables.$dark-mode-text-color

@mixin lists-style-lightmode
    color: variables.$light-mode-text-color

@mixin lists-general-style
    text-transform: uppercase
    text-decoration: none
    font-family: "Poppins", sans-serif

@mixin linear-gradient
    // background: variables.$button-border-lightmode
    height: 3rem
    background-repeat: no-repeat
    background-size: 20px 20px
    border: none

@mixin dark
    .dark, .work-container, .about-container, .contact-container, .navbar-vertical a, footer, button, footer a, .contact-address p .error-page, .contact-inputs input textarea, .upload-container
        color: variables.$dark-mode-text-color 
    .work-container, .about-container, .contact-container, footer, .navbar-container, .error-page, .upload-container
        background: variables.$background-dark-mode 
    .footer-home
        background: transparent
    .sub-menu button::before
        transform-origin: 0 50%
        background: #D9B54A
    .icon-return-to-top, .icon-darkmode
        color: variables.$icon-darkmode
    .menu button:focus, .navbar-home a:focus
        color: variables.$button-color-focus-darkmode
    input, textarea
        border: none
        border-bottom: variables.$input-border-darkmode
        background: transparent
        // box-shadow: 0 4px 30px variables.$input-box-shadow-darkmode        
        
@mixin light
    .light, .work-container, .about-container, .contact-container, .navbar-vertical a, footer, button, .menu button:focus, .navbar-home a:focus, footer a, a, .error-page, .upload-container
        color: variables.$light-mode-text-color
    .work-container, .about-container, .contact-container, footer, .navbar-container, .error-page, .upload-container
        background: variables.$background-light-mode 
    .footer-home
        background: transparent 
        color: variables.$dark-mode-text-color  
    .sub-menu button::before
        transform-origin: 0 50%
        background: #5D0003   
    .icon-return-to-top, .icon-lightmode
        color: variables.$icon-lightmode   
    input, textarea
        border: none
        border-bottom: variables.$input-border-lightmode
        background: transparent
        // box-shadow: 0 4px 30px variables.$input-box-shadow-lightmode

@mixin darkmode
        background-color: variables.$background-dark-mode
        color: variables.$dark-mode-text-color

@mixin lightmode
        background-color: variables.$background-light-mode
        color: variables.$light-mode-text-color

@mixin background-image 
    .dark, .light
        background-size: cover
        object-fit: cover
        background-repeat: no-repeat
        overflow: hidden        
        
@mixin background-home
    background-position: top
    background-repeat: no-repeat
    background-size: cover
    height: 100vh

@mixin button
    width: auto
    background-color: transparent
    border: 0
    cursor: pointer

@mixin button-navbar
    width: auto
    background-color: transparent
    border: none
    cursor: pointer
    // height: 3rem
    font-family: variables.$font-stack
    text-transform: variables.$font-uppercase
    // text-align: left

@mixin main-containers
    width: 100vw
    height: auto
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

// Media Queries

@mixin extra-big-screens
@media(min-width: variables.$breakpoint-lg)
    .about-content
        .about-img
            img
                height: 100vh        

@mixin big-screens
@media(max-width: variables.$breakpoint-lg)
    .about-content
        .about-text
            padding: 1rem 0 2rem 0
        .about-text p
            line-height: variables.$line-height-text-xs
            // font-size: 0.8em
            padding: 0 1rem


@mixin bigger-screens
    @media(min-width: 577px)
        .navbar-mobile
            visibility: hidden     

        .button-darkmode button
            position: absolute
            right: 2rem

@mixin md-screen
@media(max-width: variables.$breakpoint-md)
    .about-content        

@mixin sm-screen
@media(max-width: variables.$breakpoint-sm)
    .navbar-container
        display: flex

        .navbar-home 
            display: none
            
        .navbar-mobile
            display: block

        .navbar-container-vertical nav button
            font-size: 0.2rem

    .work-container
        .filter-container
            margin-top: 12%

    .about-container
        .about-content
            width: 100vw
            flex-direction: column
            margin-top: 10%

        .about-img, .about-text, .about-bg-image
            width: auto

        .about-img
            padding: 3rem 2rem 2rem 2rem  
        
        .about-bg-image
            width: 60%

        .about-text p
            padding: 0 2rem

    .contact-container
        width: 100vw
        flex-direction: column

    main
        .contact-content:nth-child(n+2)
            margin-top: 0

    // .image-list-grid
    //     grid-column: 2

@mixin xs-screen
@media(max-width: variables.$breakpoint-xs)
    .about-content
        .about-text p
            // line-height: variables.$line-height-text-xs
            // padding: 1rem
              



    

    

    
    




    





